import { useState } from "react";
import LoaderData from "../assets/oneplan_loader.gif"

export default function Loader() {
  return (
    <div className="grid h-screen place-items-center">
      <img className="w-28 h-28" src={LoaderData} />
    </div>
    
  )
}