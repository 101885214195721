import React, { useEffect } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Loader from "./components/loader";
import ProtectedRoute from "./utils/protectedRoute";

const LandingPage = React.lazy(() => import("./landing"))
const HowToPage = React.lazy(() => import("./how_to/index"))
const CalculatorPage = React.lazy(() => import("./calculator_page"))

const App = () => {

  // useEffect(() => {
  //   const handleStorageChange = (e) => {
  //     if (e.key === 'token') {
  //       sessionStorage.removeItem('token');
  //       window.location.href = '/'
  //       return
  //     }
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // },[])

  return (
    <Router>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/" element={<LandingPage />}></Route>
          {/* <Route element={<ProtectedRoute />}> */}
            <Route exact path="/how-to" element={<HowToPage />}></Route>
          {/* </Route> */}
          <Route exact path="/calculator" element={<CalculatorPage />}></Route>
        </Routes>
      </React.Suspense>
    </Router>
  );
};

export default App;
